export enum HeaderPromoType {
  shipping,
  deliveryNow,
  goodsComplaint,
  discounts,
  phone
}

// TODO: Will this be retrieved from API. If yes, then remove this

export const promoTypes: HeaderPromoType[] = [
  HeaderPromoType.shipping,
  HeaderPromoType.goodsComplaint,
  HeaderPromoType.discounts
]
