import React, { FC, Fragment } from 'react'
import { HeaderNotice } from '@/network/graphql.g'
import HeaderPromoDefault from '@/components/molecules/headerPromo/headerPromoDefault'
import HeaderPromoCustomize from '@/components/molecules/headerPromo/headerPromoCustomize'
import { useAsset } from '@/providers/assetsProvider'

type HeaderPromoProps = {
  headerNotice: HeaderNotice
  showHeaderPromo: boolean
  setShowHeaderPromo: React.Dispatch<React.SetStateAction<boolean>>
}

const HeaderPromo: FC<HeaderPromoProps> = ({
  headerNotice,
  showHeaderPromo,
  setShowHeaderPromo
}) => {
  const { isShutDownCountry } = useAsset()
  const hasNoticeText = !!headerNotice?.text?.length

  const shouldDisplayCustomPromo =
    (hasNoticeText || (isShutDownCountry && hasNoticeText)) && showHeaderPromo
  const shouldDisplayDefaultPromo =
    !shouldDisplayCustomPromo &&
    (!isShutDownCountry || headerNotice?.text?.length === 0)

  return (
    <Fragment>
      {shouldDisplayCustomPromo && (
        <HeaderPromoCustomize
          headerNotice={headerNotice}
          setShowHeaderPromo={setShowHeaderPromo}
        />
      )}
      {shouldDisplayDefaultPromo && (
        <HeaderPromoDefault
          display={{ tablet: 'flex', desktop: 'flex', mobile: 'none' }}
        />
      )}
    </Fragment>
  )
}

export default HeaderPromo
