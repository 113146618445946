import { useEffect, useRef, useState } from 'react'

type TimeState = Record<'startTime' | 'currentTime' | 'endTime', Date | null>

const useTimeCounter = (
  startTime: Date,
  endTime: Date,
  getNewEndTimeOnFinish?: () => Date
): TimeState => {
  const [time, setTime] = useState<TimeState>({
    startTime: startTime,
    currentTime: new Date(),
    endTime: endTime
  })

  const timeoutHandle = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (!time.startTime || !time.endTime) {
      return
    }

    if (time.currentTime >= time.endTime) {
      if (getNewEndTimeOnFinish) {
        setTime((prevTime) => ({
          ...prevTime,
          endTime: getNewEndTimeOnFinish()
        }))
      } else {
        setTime({
          startTime: null,
          currentTime: new Date(),
          endTime: null
        })
      }
    } else {
      timeoutHandle.current = setTimeout(() => {
        setTime((prevTime) => ({
          ...prevTime,
          currentTime: new Date()
        }))
      }, 1000)
    }

    return () => {
      if (timeoutHandle.current) {
        clearTimeout(timeoutHandle.current)
      }
    }
  }, [time.startTime, time.endTime, time.currentTime, getNewEndTimeOnFinish])

  return time
}

export default useTimeCounter
