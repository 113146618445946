import { FC, useContext, useMemo } from 'react'
import styled, { withTheme } from 'styled-components'
import { Flex } from '@/components/atoms/Grid'
import { Capitalized } from '@/components/atoms/typography'
import { ITheme } from '@/theme/interfaces/ITheme'
import { SingleIconProps } from '@/components/atoms/icon'
import { Translate } from 'next-translate'
import { HeaderPromoType, promoTypes } from '@/types/headerInfoPromoType'
import useTranslation from 'next-translate/useTranslation'
import { FreeShippingSmallIconDefault } from '@/components/atoms/icons/FreeShippingSmallIconDefault'
import { DiscountSmallIconDefault } from '@/components/atoms/icons/DiscountSmallIconDefault'
import { StoreContext, useDomain } from '@/providers/storeProvider'
import { MarketCode } from '@/network/graphql.g'
import { DeliveryNowIconDefault } from '@/components/atoms/icons/DeliveryNowIconDefault'
import { validFor } from '@/theme/validFor'
import { ReturnIconPlainDefault } from '@/components/atoms/icons/ReturnPlainIconDefault'
import { useAsset } from '@/providers/assetsProvider'
import { ReturnIconDefault } from '@/components/atoms/icons/ReturnIconDefault'

type HeaderPromoItem = {
  title: string
  IconElement: (props: SingleIconProps) => JSX.Element
}

type HeaderPromoProps = {
  theme: ITheme
}

const HeaderPromoDefault: FC<HeaderPromoProps> = ({ theme, ...rest }) => {
  const { t } = useTranslation('common')
  const { subdomainName } = useDomain()
  const { isFrogies } = useContext(StoreContext)

  const items = useMemo(
    () =>
      makeHeaderInfo(
        [
          ...(!isFrogies
            ? promoTypes
            : [
                ...promoTypes.slice(0, 1),
                ...[HeaderPromoType.deliveryNow],
                ...promoTypes.slice(1, 4)
              ]),
          ...(subdomainName === MarketCode.Rs ? [HeaderPromoType.phone] : [])
        ],
        t
      ),
    []
  )
  return (
    <Wrapper {...rest}>
      {items?.map(({ title, IconElement }, offset) => (
        <Flex
          flexDirection={'row'}
          mr={{
            tablet: offset + 1 < items.length ? 24 : 0,
            desktop: offset + 1 < items.length ? 5 : 0
          }}
          key={title}
        >
          {
            <IconElement
              color={theme.colors.background}
              width={16}
              height={16}
            />
          }
          <Capitalized ml={14}>{title.toUpperCase()}</Capitalized>
        </Flex>
      ))}
    </Wrapper>
  )
}

const makeHeaderInfo = (
  headerInfoItems: HeaderPromoType[],
  t: Translate
): HeaderPromoItem[] =>
  headerInfoItems?.map((type) => {
    const { isReturnIconDateInterval } = useAsset()
    switch (type) {
      case HeaderPromoType.shipping:
        return {
          title: t('Menu.Promotions.freeShipping'),
          IconElement: FreeShippingSmallIconDefault
        }
      case HeaderPromoType.deliveryNow:
        return {
          title: t('Menu.Promotions.deliveryNow'),
          IconElement: DeliveryNowIconDefault
        }
      case HeaderPromoType.goodsComplaint:
        return {
          title: t('Menu.Promotions.goodsComplaint'),
          IconElement: isReturnIconDateInterval
            ? ReturnIconPlainDefault
            : ReturnIconDefault
        }
      case HeaderPromoType.discounts:
        return {
          title: t('Menu.Promotions.discounts'),
          IconElement: DiscountSmallIconDefault
        }
      case HeaderPromoType.phone:
        return {
          title: t('Contact.phone'),
          IconElement: () => (
            <Flex alignSelf="center" className="fcicon-phone" />
          )
        }
    }
  })

export default withTheme(HeaderPromoDefault)

const Wrapper = styled(Flex)`
  ${validFor.tablet`
    padding: 0 24px;
  `}
  justify-content: center;
  background-color: ${(props) => props.theme.colors.onBackground};
  color: ${(props) => props.theme.colors.background};
`
