import React, {
  FC,
  Fragment,
  useEffect,
  useLayoutEffect,
  useState
} from 'react'
import styled from 'styled-components'
import { Box, Flex } from '@/components/atoms/Grid'
import useTranslation from 'next-translate/useTranslation'
import { HeaderNotice } from '@/network/graphql.g'
import { validFor } from '@/theme/validFor'
import { CloseIconButton } from '@/components/atoms/button'
import { DateTime } from 'luxon'
import useTimeCounter from '../../../hooks/useTimeCounter'
import { calculateTimeLeft } from '@/core/timeHelper'

type HeaderPromoProps = {
  headerNotice: HeaderNotice
  setShowHeaderPromo: React.Dispatch<React.SetStateAction<boolean>>
}

const HeaderPromoCustomize: FC<HeaderPromoProps> = ({
  headerNotice,
  setShowHeaderPromo
}) => {
  const { t } = useTranslation('common')
  const [remainingTime, setRemainingTime] = useState<
    { count: string | number; unit: string }[]
  >([])
  const startTime = headerNotice?.promoCode?.discountStart
    ? new Date(headerNotice?.promoCode?.discountStart * 1000)
    : null

  const endTime = headerNotice?.promoCode?.discountEnd
    ? new Date(headerNotice?.promoCode?.discountEnd * 1000)
    : null

  const headerPromoCounter = useTimeCounter(startTime, endTime)

  let replacedStringHeaderNotice = headerNotice?.text || ''

  if (startTime && endTime !== null) {
    const headerPromoCounterTimeLeft = calculateTimeLeft(
      headerPromoCounter.currentTime,
      headerPromoCounter.endTime
    )

    const timeLeft = Object.keys(headerPromoCounterTimeLeft).map((key) => {
      const count = headerPromoCounterTimeLeft[key]
      return {
        count,
        unit: typeof count === 'number' ? t(`Time.${key}`, { count }) : null
      }
    })

    useEffect(() => {
      setRemainingTime(timeLeft)
    }, [])

    if (timeLeft && timeLeft.length > 0) {
      replacedStringHeaderNotice = timeLeft
        .map(({ count, unit }, index) =>
          index === 0
            ? `${count} ${unit} `
            : index === 1 || index === 2
            ? `${count}:`
            : `${count}`
        )
        .join('')
    }
  }

  const finalHeaderNotice = headerNotice?.text?.replace(
    /\{promocode_end_time\}/g,
    `<div style="width: 160px">${replacedStringHeaderNotice}</div>`
  )

  const closeHeader = () => {
    setShowHeaderPromo(false)
    const closedData = {
      closed: true,
      closedTimestamp: DateTime.local().toMillis()
    }
    if (typeof window !== 'undefined') {
      localStorage.setItem('headerPromoClosedData', JSON.stringify(closedData))
    }
  }

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      const storedClosedData = JSON.parse(
        localStorage.getItem('headerPromoClosedData')
      )
      const currentTime = DateTime.local().toMillis()
      if (storedClosedData) {
        const headerShouldVisible = !storedClosedData.closed
          ? currentTime - storedClosedData.closedTimestamp < 24 * 60 * 60 * 1000
          : null
        setShowHeaderPromo(headerShouldVisible)
        if (
          !headerShouldVisible
            ? currentTime - storedClosedData.closedTimestamp >
              24 * 60 * 60 * 1000
            : null
        ) {
          localStorage.removeItem('headerPromoClosedData')
        }
      }
    }
  }, [])

  return (
    <Fragment>
      {remainingTime && (
        <StyledFlex headerNotice={headerNotice}>
          <Box />
          <StyledLink>
            <Flex
              dangerouslySetInnerHTML={{
                __html: finalHeaderNotice
              }}
              alignItems="center"
              justifyContent="center"
            />
          </StyledLink>
          <Box mr={{ desktop: 3, tablet: 3, mobile: 0 }}>
            <CloseIconButton
              onClick={closeHeader}
              width={12}
              height={12}
              showBackground={false}
              color={headerNotice?.fontColor}
            />
          </Box>
        </StyledFlex>
      )}
    </Fragment>
  )
}

export default HeaderPromoCustomize

const StyledFlex = styled(Flex)`
  background-color: ${(props) => props.headerNotice?.backgroundColor};
  color: ${(props) => props.headerNotice?.fontColor};
  height: 38px;
  font-size: 15.5px;
  ${validFor.mobile`
    height: 50px;
     padding: 0px 5px;
  `}
  align-items: center;
  justify-content: space-between;
  text-align: center;
  position: relative;
  z-index: 90;
  a {
    color: ${(props) => props.headerNotice?.fontColor};
  }
`
const StyledLink = styled.a`
  color: ${(props) => props.headerNotice?.fontColor};
`
