import { Translate } from 'next-translate'
import { DateTime, Interval } from 'luxon'
import { Delivery, TimeUnit } from '@/network/graphql.g'

export const calculateTimeLeft = (
  startDate: Date,
  endDate: Date
): { days: number; hours: string; minutes: string; seconds: string } => {
  if (!startDate || !endDate) {
    return {
      days: 0,
      hours: '00',
      minutes: '00',
      seconds: '00'
    }
  }

  const interval = Interval.fromDateTimes(
    toLuxonDate(startDate),
    toLuxonDate(endDate)
  )

  if (!interval.isValid) {
    return {
      days: 0,
      hours: '00',
      minutes: '00',
      seconds: '00'
    }
  }

  const duration = interval
    .toDuration(['days', 'hours', 'minutes', 'seconds'])
    .toObject()

  return {
    days: Math.round(duration.days),
    hours: Math.round(duration.hours).toString().padStart(2, '0'),
    minutes: Math.round(duration.minutes).toString().padStart(2, '0'),
    seconds: Math.round(duration.seconds).toString().padStart(2, '0')
  }
}

export const convertDeliveryToTimeStringCart = (
  delivery: Delivery,
  t: Translate
): string => {
  const deliveryTimes = [delivery?.lowest, delivery?.highest]
  return addTimeUnitCart(
    deliveryTimes.filter((item) => item).join(' - '),
    delivery?.unit,
    t
  )
}

const addTimeUnitCart = (
  value: string,
  timeUnit: TimeUnit,
  t: Translate
): string => {
  const numbers = value.split('-')
  const lastNumber = parseInt(numbers[numbers.length - 1].trim())
  const count = lastNumber ? lastNumber : 999
  if (
    (count === 1 && timeUnit === TimeUnit.Days) ||
    (count === 24 && timeUnit === TimeUnit.Hours)
  ) {
    return `${t(`Cart.Delivery.inStorage`)}`
  }
  let unit = ''
  if (timeUnit === TimeUnit.Hours) {
    unit = t(`Time.h`, { count })
  } else if (timeUnit === TimeUnit.Days) {
    unit = t(`Time.days`, { count })
  }
  return ` ${t(`Cart.Delivery.toOrder`)} (${value} ${unit})`
}

export const convertDeliveryToTimeString = (
  delivery: Delivery,
  t: Translate,
  isSentImmediately = false,
  showTypeDay = true
): string => {
  const deliveryTimes = [delivery?.lowest, delivery?.highest]
  return addTimeUnit(
    deliveryTimes.filter((item) => item).join(' - '),
    delivery?.unit,
    t,
    isSentImmediately,
    showTypeDay
  )
}

const addTimeUnit = (
  value: string,
  timeUnit: TimeUnit,
  t: Translate,
  isSentImmediately: boolean,
  showTypeDay: boolean
): string => {
  const numbers = value.split('-')
  const lastNumber = parseInt(numbers[numbers.length - 1].trim())
  const count = lastNumber ? lastNumber : 999
  if (
    (count === 1 && timeUnit === TimeUnit.Days) ||
    (count === 24 && timeUnit === TimeUnit.Hours)
  ) {
    return isSentImmediately
      ? `${t(`Cart.Delivery.immediately`)}`
      : `${t(`Cart.Delivery.inStorage`)}`
  }
  let unit = ''
  if (timeUnit === TimeUnit.Hours) {
    unit = t(`Time.h`, { count })
  } else if (timeUnit === TimeUnit.Days) {
    unit = t(`Time.days`, { count })
  }

  let typeDay = ''
  if (timeUnit === TimeUnit.Hours) {
    typeDay = t(`Time.typeDay`, { count })
  } else if (timeUnit === TimeUnit.Days) {
    typeDay = t(`Time.typeDay`, { count })
  }

  if (showTypeDay) {
    return `${value} ${unit}`
  } else {
    return `${value} ${typeDay} ${unit}`
  }
}

const toLuxonDate = (date: DateTime | Date): DateTime =>
  date ? (DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date)) : null

export const toShortDate = (date: DateTime | Date, locale: string): string =>
  toLuxonDate(date)?.setLocale(locale).toLocaleString(DateTime.DATE_SHORT)

export const toLongDateShortTime = (
  date: DateTime | Date,
  locale: string
): string => {
  const localizedDate = toLuxonDate(date)?.setLocale(locale)
  return `${localizedDate.toLocaleString(
    DateTime.DATE_FULL
  )}, ${localizedDate.toLocaleString(DateTime.TIME_SIMPLE)}`
}
