import { FC } from 'react'
import { SvgProps } from './svgProps'

const DiscountSmallIcon: FC<SvgProps> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 16}
    height={height || 16}
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            stroke={color || '#FFF'}
            strokeLinecap="round"
            d="M7.172 14.376l-.703.32c-.79.358-1.721.009-2.08-.78-.015-.033-.028-.066-.04-.1l-.432-1.148c-.101-.27-.315-.484-.585-.585l-1.149-.431c-.812-.305-1.224-1.21-.92-2.023.013-.033.027-.066.042-.098l.507-1.117c.12-.263.12-.565 0-.828L1.305 6.47c-.36-.79-.01-1.721.78-2.08.032-.015.065-.028.098-.04l1.15-.432c.27-.101.483-.315.584-.585l.431-1.149c.305-.812 1.21-1.224 2.023-.92.033.013.066.027.098.042l1.117.507c.263.12.565.12.828 0l1.117-.507c.79-.36 1.721-.01 2.08.78.015.032.028.065.04.098l.432 1.15c.101.27.315.483.585.584l1.149.431c.812.305 1.224 1.21.92 2.023-.013.033-.027.066-.042.098l-.507 1.117c-.12.263-.12.565 0 .828l.507 1.117c.36.79.01 1.721-.78 2.08-.032.015-.065.028-.098.04l-1.15.432c-.27.101-.483.315-.584.585l-.431 1.149c-.305.812-1.21 1.224-2.023.92-.033-.013-.066-.027-.098-.042l-.703-.32c-.526-.238-1.13-.238-1.656 0z"
            transform="translate(-688 -38) translate(688 38)"
          />
          <path
            fill="#FFF"
            d="M9.6 8.533c.59 0 1.067.478 1.067 1.067 0 .59-.478 1.067-1.067 1.067-.59 0-1.067-.478-1.067-1.067 0-.59.478-1.067 1.067-1.067zm.377-2.51c.208.208.208.546 0 .754l-3.2 3.2c-.208.208-.546.208-.754 0-.208-.208-.208-.546 0-.754l3.2-3.2c.208-.208.546-.208.754 0zM6.4 5.333c.59 0 1.067.478 1.067 1.067 0 .59-.478 1.067-1.067 1.067-.59 0-1.067-.478-1.067-1.067 0-.59.478-1.067 1.067-1.067z"
            transform="translate(-688 -38) translate(688 38)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default DiscountSmallIcon
